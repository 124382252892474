import { Injectable } from '@angular/core';
import { isInStock } from '@app/custom/features/rrs-product-details/helpers/product';
import { RrsProductScope } from '@app/spartacus/configurations/types.config';
import { CmsService, Product } from '@spartacus/core';
import { SchemaBuilder, CurrentProductService } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsProductSchemaBuilder implements SchemaBuilder {
  constructor(
    protected currentProduct: CurrentProductService,
    protected cmsService: CmsService
  ) {}

  build(): Observable<any> {
    return combineLatest([
      this.currentProduct.getProduct([RrsProductScope.ALL]),
      this.cmsService.getCurrentPage(),
    ]).pipe(
      map(([product, page]) => {
        if (!product) {
          return {};
        }

        if (page?.schema) {
          return page.schema;
        }

        return this.collect(product);
      })
    );
  }

  protected collect(product: Product): any {
    if (!product.code) {
      return {};
    }
    const schema: any = {
      '@context': 'http://www.schema.org',
      '@type': 'Product',
      sku: product.code,
    };

    const brand = product.categories?.find((category) =>
      category.code?.includes('brand')
    )?.name;
    const color = product.categories?.find((category) =>
      category.code?.includes('color')
    )?.name;
    const categories = product.categories
      ?.filter((category) =>
        Boolean(
          category.name &&
            !category.code?.includes('brand') &&
            !category.code?.includes('color')
        )
      )
      .map((category) => category.name);
    const primaryImage = Object.values(product.images?.PRIMARY ?? {})[0];
    const image = primaryImage?.url;

    if (brand) schema.brand = { '@type': 'Brand', name: brand };
    if (categories?.length) schema.category = categories;
    if (color) schema.color = color;
    if (product.name)
      schema.name = `${product.name}${color?.length ? ` - ${color}` : ''}`;
    if (image) schema.image = image;
    if (product.description) schema.description = product.description;

    schema.offers = {
      '@type': 'Offer',
      priceCurrency: product.price?.currencyIso,
      price: product.price?.value,
      availability: isInStock(product)
        ? 'http://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
      url: `https://www.rackroomshoes.com${product.url}`,
    };

    return schema;
  }
}
