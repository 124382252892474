import {
  AuthService,
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ResetPasswordComponent } from '@spartacus/user/profile/components';
import { RrsResetPasswordService } from '@app/custom/features/rrs-reset-password/services/rrs-reset-password.service';
import { UserPasswordFacade } from '@spartacus/user/profile/root';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-reset-password',
  styleUrls: ['./rrs-reset-password.component.scss'],
  templateUrl: './rrs-reset-password.component.html',
  providers: [
    {
      provide: RrsResetPasswordService,
      useClass: RrsResetPasswordService,
      deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
    },
  ],
})
export class RrsResetPasswordComponent
  extends ResetPasswordComponent
  implements OnInit, OnDestroy
{
  protected subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected service: RrsResetPasswordService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.token$, this.authService.isUserLoggedIn()])
        .pipe(
          take(1),
          map(([token, isAuth]) => {
            if (!token || isAuth) {
              this.routingService.go({ cxRoute: 'home' });
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
