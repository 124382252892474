<ng-container *ngIf="isUpdating$ | async"></ng-container>

<header class="modal-header d-flex align-items-center justify-content-between">
  <h4 class="mb-0">
    {{ 'rrs.account.accountInformation' | cxTranslate }}
  </h4>

  <button
    (click)="close()"
    aria-label="Close"
    class="btn btn-close"
    type="button"></button>
</header>

<form
  #ngForm="ngForm"
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="modal-body">
  <p class="mb-3">
    {{ 'rrs.account.accountInformationMessage' | cxTranslate }}
  </p>

  <div class="mb-3">
    <label class="form-label" for="email">
      {{ 'rrs.account.form.email' | cxTranslate }}
    </label>

    <input
      [ngClass]="
        ngForm.submitted ? (form.get('email') | rrsIsControlValid) : ''
      "
      aria-required="true"
      class="form-control"
      formControlName="email"
      name="email"
      type="email" />

    <rrs-form-errors *ngIf="ngForm.submitted" [control]="form.get('email')">
    </rrs-form-errors>
  </div>

  <p class="mb-3">
    {{ 'rrs.account.accountInformationPasswordMessage' | cxTranslate }}
  </p>

  <div class="mb-3">
    <label class="form-label" for="password">
      {{ 'rrs.account.form.password' | cxTranslate }}
    </label>

    <input
      [ngClass]="
        ngForm.submitted ? (form.get('password') | rrsIsControlValid) : ''
      "
      aria-required="true"
      class="form-control"
      formControlName="password"
      name="password"
      type="password" />

    <rrs-form-errors *ngIf="ngForm.submitted" [control]="form.get('password')">
    </rrs-form-errors>
  </div>

  <div class="d-flex gap-3">
    <button (click)="close()" class="btn btn-block btn-secondary" type="button">
      {{ 'common.cancel' | cxTranslate }}
    </button>

    <button [disabled]="form.disabled" class="btn btn-block btn-primary">
      {{ 'common.save' | cxTranslate }}
    </button>
  </div>
</form>
