<ng-container *ngIf="token$ | async as token">
  <div class="reset-password pt-5">
    <div class="container d-flex justify-content-center">
      <div class="bg-white p-0 col-12 col-lg-6">
        <ng-container *ngIf="isUpdating$ | async"></ng-container>

        <header class="p-3 bg-stripe border-top border-accent border-2">
          <h1 class="fs-4 fw-normal mb-0">
            {{ 'rrs.account.setupNewPassword' | cxTranslate }}
          </h1>
        </header>

        <form
          #ngForm="ngForm"
          (ngSubmit)="onSubmit(token)"
          [formGroup]="form"
          class="p-3">
          <div class="mb-3">
            <label for="email" class="form-label">
              {{ 'rrs.forms.passwordField.newPassword' | cxTranslate }}
            </label>

            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('password') | rrsIsControlValid)
                  : ''
              "
              formControlName="password"
              name="password"
              type="password" />

            <rrs-password-errors
              [errors]="form.get('password').errors"
              [showErrors]="
                form.get('password')?.touched || form.get('password')?.dirty
              "></rrs-password-errors>
          </div>

          <div class="mb-4">
            <label for="passwordConfirm" class="form-label">
              {{ 'rrs.forms.passwordField.confirmPassword' | cxTranslate }}
            </label>

            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('passwordConfirm') | rrsIsControlValid)
                  : ''
              "
              formControlName="passwordConfirm"
              name="passwordConfirm"
              type="password" />

            <rrs-form-errors
              *ngIf="ngForm.submitted"
              [control]="form.get('passwordConfirm')"></rrs-form-errors>
          </div>

          <div class="row row-cols-1 row-cols-lg-2">
            <div class="mb-2 mb-lg-0">
              <button class="btn btn-primary" [disabled]="form.disabled">
                {{ 'register.resetPassword' | cxTranslate }}
              </button>
            </div>

            <div>
              <a
                [routerLink]="{ cxRoute: 'home' } | cxUrl"
                class="btn btn-secondary"
                [disabled]="form.disabled">
                {{ 'common.cancel' | cxTranslate }}
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
