<img
  [ngClass]="isSearchBoxImg ? 'search-box-img' : 'media-img'"
  *ngIf="media && media.src"
  [ngSrc]="media.src"
  [ngSrcSet]="media.srcset"
  [height]="height"
  [width]="width"
  [alt]="media.alt"
  [role]="media.role"
  [loading]="!priority && loading"
  [sizes]="sizes"
  (load)="loadHandler()"
  (error)="errorHandler()"
  [priority]="priority" />
