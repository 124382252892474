import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { RrsAccountInformationComponent } from '@app/custom/features/rrs-profile/components/rrs-account-information/rrs-account-information.component';
import { RrsAccountInformationUpdateComponent } from '@app/custom/features/rrs-profile/components/rrs-account-information-update/rrs-account-information-update.component';
import { RrsAccountStoreComponent } from './components/rrs-account-store/rrs-account-store.component';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsIconModule } from '@app/shared/components';
import { RrsPersonalInformationComponent } from '@app/custom/features/rrs-profile/components/rrs-personal-information/rrs-personal-information.component';
import { RrsPersonalInformationUpdateComponent } from './components/rrs-personal-information-update/rrs-personal-information-update.component';
import { RrsProfileComponent } from '@app/custom/features/rrs-profile/components/rrs-profile/rrs-profile.component';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { MediaModule } from '@spartacus/storefront';
import { RrsStoreFinderComponentsModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder-components.module';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { RrsOccUserProfileAdapter } from '@app/custom/features/rrs-profile/services/rrs-profile.adapter';
import {
  UserProfileAdapter,
  UserProfileConnector,
} from '@spartacus/user/profile/core';
import { RrsUserProfileConnector } from '@app/custom/features/rrs-profile/services/rrs-user-profile.connector';
import { RrsUserProfileService } from '@app/custom/features/rrs-profile/services/rrs-user-profile.service';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { RrsGlobalMessageModule } from '@app/custom/features/rrs-global-message';
import { RrsMonthOfBirthPipe } from './pipes/rrs-month-of-birth.pipe';

@NgModule({
  declarations: [
    RrsAccountInformationComponent,
    RrsAccountInformationUpdateComponent,
    RrsPersonalInformationComponent,
    RrsPersonalInformationUpdateComponent,
    RrsProfileComponent,
    RrsAccountStoreComponent,
    RrsMonthOfBirthPipe,
  ],
  exports: [RrsProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    MediaModule,
    NgSelectModule,
    ReactiveFormsModule,
    RrsFormErrorsModule,
    RrsGlobalMessageModule,
    RrsIconModule,
    RrsSharedDirectivesModule,
    RrsSharedPipesModule,
    RrsStoreFinderComponentsModule,
  ],
  providers: [
    {
      provide: UserProfileConnector,
      useExisting: RrsUserProfileConnector,
    },
    {
      provide: UserProfileAdapter,
      useExisting: RrsOccUserProfileAdapter,
    },
    {
      provide: UserProfileFacade,
      useExisting: RrsUserProfileService,
    },
  ],
})
export class RrsProfileModule {}
