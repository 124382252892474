import {
  FRESH_CHAT_CONFIG,
  FRESH_CHAT_HOST,
  FRESH_CHAT_SCRIPT_SRC,
  FRESH_CHAT_TOKEN,
  PRE_CHAT_SCRIPT_SRC,
  PRE_CHAT_TEMPLATE,
} from '@app/custom/features/rrs-freshchat/rrs-freshchat.config';
import { Inject, Injectable } from '@angular/core';
import { UserIdService, WindowRef } from '@spartacus/core';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';

declare global {
  interface Window {
    fcSettings: any;
    fcWidget: any;
    fcPreChatform: any;
    openHelp: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RrsFreshChatService {
  nativeWindow = this.winRef.nativeWindow as Window;

  constructor(
    private userIdService: UserIdService,
    protected winRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    protected eventsDispatcherService: RrsEventsDispatcherService
  ) {}

  initFreshChat(): void {
    const freshchatPreChatFormScript = this.document.createElement('script');
    freshchatPreChatFormScript.async = true;
    freshchatPreChatFormScript.src = PRE_CHAT_SCRIPT_SRC;

    const freshchatWidgetScript = this.document.createElement('script');
    freshchatWidgetScript.async = true;
    freshchatWidgetScript.src = FRESH_CHAT_SCRIPT_SRC;
    freshchatWidgetScript.onload = (): void => {
      this.nativeWindow.openHelp = (): void => this.open();
    };
    freshchatPreChatFormScript.onload = (): void => {
      this.userIdService
        .getUserId()
        .pipe(take(1))
        .subscribe((id) => {
          this.nativeWindow.fcSettings = {
            token: FRESH_CHAT_TOKEN,
            host: FRESH_CHAT_HOST,
            externalId: id,
            config: FRESH_CHAT_CONFIG,
            onInit: function (): void {
              window.fcPreChatform.fcWidgetInit(PRE_CHAT_TEMPLATE);
            },
          };
        });
      this.document.head.appendChild(freshchatWidgetScript);
    };
    this.document.head.appendChild(freshchatPreChatFormScript);
  }

  private getWidget(): any {
    return this.nativeWindow.fcWidget;
  }

  open(payload?: {
    name?: string;
    channelId?: string;
    replyText?: string;
  }): void {
    this.eventsDispatcherService.dispatchLiveChatEvent();
    this.getWidget().open(payload);
  }

  close(): void {
    this.getWidget().close();
  }
}
