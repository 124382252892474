<section *ngIf="cart$ | async as cart" class="me-lg-3">
  <rrs-order-summary [cart]="cart"></rrs-order-summary>

  <cx-page-slot position="campaign_message_slot_1"></cx-page-slot>

  <ng-container *ngIf="isUserLogged$ | async">
    <rrs-select-rewards></rrs-select-rewards>
  </ng-container>

  <rrs-cart-coupon></rrs-cart-coupon>

  <rrs-donation></rrs-donation>

  <div class="px-2 px-lg-0">
    <div class="row gx-0 gy-2 mb-4">
      <div class="col-12">
        <a
          (clikEvent)="disableButtonWhileNavigation()"
          [disabled]="cartValidationInProgress"
          [loading]="cartValidationInProgress"
          (click)="goToCheckout()"
          class="btn btn-primary">
          <rrs-icon [icon]="iconTypeList.CART" [size]="16"></rrs-icon>
          {{ 'cart.checkout' | cxTranslate }}
        </a>
      </div>
      <div *ngIf="isExpressCheckoutAvailable" class="col-12">
        <a
          (clikEvent)="disableButtonWhileNavigation()"
          (click)="doExpressCheckout(cart)"
          [disabled]="cartValidationInProgress"
          [loading]="cartValidationInProgress"
          class="btn btn-secondary">
          {{ 'cart.expressCheckout' | cxTranslate }}
        </a>
      </div>

      <div class="col-12">
        <div id="paypal-button"></div>
      </div>

      <div class="col-12 d-flex justify-content-around">
        <a
          [routerLink]="{ cxRoute: 'home' } | cxUrl"
          class="link link-light fw-normal text-uppercase d-flex">
          {{ 'cart.keepShopping' | cxTranslate }}
        </a>
        <rrs-add-to-saved-cart></rrs-add-to-saved-cart>
      </div>
    </div>
  </div>

  <cx-page-slot position="legal_content"></cx-page-slot>
</section>
<div
  *ngIf="checkoutService.expressCheckoutInProgress"
  style="left: 0"
  class="d-flex align-items-center justify-content-center position-fixed top-0 left-0 vh-100 w-100 bg-white z-2000 opacity-75">
  <div class="spinner-border" role="status" style="width: 8rem; height: 8rem">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
