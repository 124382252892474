import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';

@Pipe({
  name: 'rrsUrlQueryParams',
})
export class RrsUrlQueryParamsPipe implements PipeTransform {
  transform(url: string): Params {
    const params: Params = {};

    const decodedUrl = decodeURIComponent(url);

    if (decodedUrl.includes('?')) {
      const queryParamsString = decodedUrl.split('?')[1];
      const queryParams = new URLSearchParams(queryParamsString);

      for (let [key, param] of queryParams) {
        params[key] = param;
      }
    }
    return params;
  }
}
