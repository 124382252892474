import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsCheckoutService } from '@app/custom/features/rrs-checkout/rrs-checkout.service';
import { RrsPayPalService } from '@app/custom/features/rrs-pay-pal/rrs-pay-pal.service';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { CartTotalsComponent } from '@spartacus/cart/base/components';
import { Cart } from '@spartacus/cart/base/root';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
import { AddressBookComponentService } from '@spartacus/storefront';

import { Observable, Subscription, of } from 'rxjs';
import { distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-cart-summary',
  templateUrl: './rrs-cart-summary.component.html',
})
export class RrsCartSummaryComponent
  extends CartTotalsComponent
  implements OnInit, AfterViewInit
{
  subscriptions = new Subscription();
  iconTypeList = ICON_TYPE_LIST;
  isUserLogged$: Observable<boolean> = this.authService.isUserLoggedIn();

  public addresses$ = this.isUserLogged$.pipe(
    distinctUntilChanged(),
    tap((isLogged: boolean) => {
      if (isLogged) {
        this.addressBookService.loadAddresses();
      }
    }),
    switchMap((isLogged: boolean) => {
      if (isLogged) {
        return this.addressBookService.getAddresses();
      }
      return of(null);
    })
  );
  protected isExpressCheckoutAvailable = false;

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected addressBookService: AddressBookComponentService,
    protected authService: AuthService,
    protected rrsAuthService: RrsAuthService,
    protected routingService: RoutingService,
    protected eventDispatcher: RrsEventsDispatcherService,
    protected checkoutService: RrsCheckoutService,
    protected cd: ChangeDetectorRef,
    protected payPalService: RrsPayPalService,
    protected globalMessageService: GlobalMessageService
  ) {
    super(activeCartService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isUserLogged$.pipe(take(1)).subscribe((isUserLoggedIn) => {
      if (isUserLoggedIn) {
        this.checkoutService
          .checkExpressCheckoutAvailable()
          .pipe(take(1))
          .subscribe((isAvailable) => {
            this.isExpressCheckoutAvailable = isAvailable;
            this.cd.detectChanges();
          });
      }
    });
  }

  goToCheckout(): void {
    this.isUserLogged$.pipe(take(1)).subscribe((isLogged) => {
      if (!isLogged) {
        this.rrsAuthService.openLoginModal(true);
      } else {
        this.routingService.go('checkout');
      }
    });
  }

  ngAfterViewInit(): void {
    this.payPalService
      .initPaypal(this.doExpressCheckout.bind(this), {
        color: 'gold',
        height: 50,
        label: 'checkout',
        tagline: true,
        disableMaxWidth: true,
      })
      ?.catch((error) => {
        this.globalMessageService.add(
          'PayPal Error: Service failed to load. Please try again.',
          GlobalMessageType.MSG_TYPE_ERROR
        );
        console.error(`PayPal Error: ${error}`);
      });
  }

  doExpressCheckout(cart: Cart, paypalPaymentDetails = null): void {
    this.checkoutService.expressCheckoutInProgress = true;
    this.cd.detectChanges();
    this.checkoutService
      .doExpressCheckout(paypalPaymentDetails)
      .pipe(take(1))
      .subscribe(() => {
        this.dispatchExpressCheckoutEvent(cart);
        this.routingService.go('checkout/review-order');
        this.checkoutService.expressCheckoutInProgress = false;
        this.cd.detectChanges();
      });
  }

  dispatchExpressCheckoutEvent(cart: Cart): void {
    this.eventDispatcher.dispatchExpressCheckoutEvent(
      (cart.entries ?? []).map((entry) => ({
        productInfo: { sku: entry.product?.baseProduct ?? '' },
      }))
    );
  }
}
