import {
  AuthRedirectService,
  AuthService,
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RrsAccountInformationService } from '@app/custom/features/rrs-profile/services/rrs-account-information.service';
import { UpdateEmailComponent } from '@spartacus/user/profile/components';
import { UserEmailFacade } from '@spartacus/user/profile/root';

@Component({
  selector: 'rrs-account-information-update',
  templateUrl: './rrs-account-information-update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: RrsAccountInformationService,
      useClass: RrsAccountInformationService,
      deps: [
        AuthRedirectService,
        AuthService,
        GlobalMessageService,
        NgbModal,
        RoutingService,
        UserEmailFacade,
      ],
    },
  ],
})
export class RrsAccountInformationUpdateComponent extends UpdateEmailComponent {
  constructor(
    protected modalService: NgbModal,
    protected service: RrsAccountInformationService
  ) {
    super(service);
  }

  close(): void {
    this.modalService.dismissAll();
  }
}
