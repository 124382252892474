<div class="address-book__title d-flex p-2 mb-lg-3 align-items-center">
  <h2 class="me-3 mb-0 fw-normal">
    {{ 'rrs.addressBook.shippingAddresses' | cxTranslate }}
  </h2>
  <rrs-icon
    [size]="32"
    [icon]="icon.MAP_PIN"
    class="d-none d-lg-block pb-1"></rrs-icon>
</div>

<div class="container-fluid p-0">
  <div class="row g-0 g-lg-4">
    <ng-container *ngIf="addresses$ | async as addresses">
      <div
        *ngFor="let address of addresses"
        class="col-lg-6 address-card mb-4"
        data-cy="addressCard">
        <h3 class="address-card__title fw-semibold p-2 mb-3 text-capitalize">
          {{ address.nickname ? address.nickname : address.line1 }}
        </h3>
        <div class="mx-3">
          <span class="fw-bold">{{
            'rrs.addressBook.name' | cxTranslate
          }}</span>
          <p class="fw-bold">{{ address.firstName }} {{ address.lastName }}</p>
          <div class="address-card__address">
            <span class="fw-bold">{{
              'rrs.addressBook.address' | cxTranslate
            }}</span>
            <div>{{ address.line1 }}</div>
            <div *ngIf="address.line2">{{ address.line2 }}</div>
            <div>
              <span>{{ address.town }}&nbsp;</span>
              <span *ngIf="address?.region?.isocode"
                >{{ address.region.isocodeShort }}&nbsp;</span
              >
              <span>{{ address.postalCode }}</span>
            </div>
          </div>
          <div class="d-flex my-3 w-100 justify-content-end">
            <div class="form-check form-switch">
              <label class="form-check-label" for="defaultAddress">
                <h4
                  *ngIf="!address.defaultAddress"
                  class="fs-7 m-0 fw-semibold">
                  {{ 'rrs.addressBook.makeDefault' | cxTranslate }}
                </h4>
                <h4 *ngIf="address.defaultAddress" class="fs-7 m-0 fw-semibold">
                  {{ 'rrs.addressBook.default' | cxTranslate }}
                </h4>
              </label>
              <input
                class="form-check-input m-0 mx-3"
                type="checkbox"
                role="switch"
                id="defaultAddress"
                data-cy="default"
                [class.pe-none]="address.defaultAddress"
                [checked]="address.defaultAddress"
                (click)="setAddressAsDefault(address.id)" />
            </div>
            <button
              type="button"
              class="me-3"
              data-cy="delete"
              (click)="openConfirmationModal(address)">
              <rrs-icon
                [size]="24"
                [icon]="icon.CLOSE_BIG"
                [attr.aria-label]="
                  'rrs.common.remove' | cxTranslate
                "></rrs-icon>
            </button>
            <button
              (click)="openFormModal(address)"
              type="button"
              data-cy="edit">
              <rrs-icon
                [size]="24"
                [icon]="icon.EDIT"
                [attr.aria-label]="'rrs.common.edit' | cxTranslate"></rrs-icon>
            </button>
          </div>
        </div>
        <footer class="p-2 bg-stripe"></footer>
      </div>
      <p
        *ngIf="!addresses?.length"
        data-cy="noAddressesFound"
        class="mt-3 mt-lg-0 ms-3 ms-lg-0 mb-5">
        {{ 'rrs.addressBook.noAddresses' | cxTranslate }}
      </p>
    </ng-container>

    <div class="col-lg-6 address-card">
      <div class="d-flex m-3 flex-column justify-content-center text-center">
        <p class="mb-5">{{ 'rrs.addressBook.addNewAddress' | cxTranslate }}</p>
        <button
          type="button"
          data-cy="addNewAddress"
          (click)="openFormModal()"
          [attr.aria-label]="'rrs.common.add' | cxTranslate">
          <rrs-icon [size]="28" [icon]="icon.ADD_CIRCLE"></rrs-icon>
        </button>
      </div>
    </div>
  </div>
</div>
