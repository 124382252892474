<ng-container *ngIf="token$ | async as token">
  <div class="container py-5">
    <div class="d-flex justify-content-center">
      <div class="col-lg-6 mw-1">
        <ng-container *ngIf="isUpdating$ | async"></ng-container>

        <h1 class="text-uppercase fs-5 fw-semibold mb-3">
          {{ 'rrs.account.setPassword.title' | cxTranslate }}
        </h1>

        <p class="mb-3">
          {{ 'rrs.account.setPassword.description' | cxTranslate }}
        </p>

        <form #ngForm="ngForm" (ngSubmit)="onSubmit(token)" [formGroup]="form">
          <div class="mb-3">
            <label for="password" class="form-label">
              {{ 'rrs.account.setPassword.passwordField.label' | cxTranslate }}
            </label>

            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('password') | rrsIsControlValid)
                  : ''
              "
              formControlName="password"
              name="password"
              type="password"
              autocomplete="new-password" />

            <rrs-password-errors
              [errors]="form.get('password').errors"
              [showErrors]="
                form.get('password')?.touched || form.get('password')?.dirty
              "></rrs-password-errors>
          </div>

          <div class="mb-4">
            <label for="passwordConfirm" class="form-label">
              {{ 'rrs.forms.passwordField.confirmPassword' | cxTranslate }}
            </label>

            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('passwordConfirm') | rrsIsControlValid)
                  : ''
              "
              formControlName="passwordConfirm"
              name="passwordConfirm"
              type="password"
              autocomplete="new-password" />

            <rrs-form-errors
              *ngIf="ngForm.submitted"
              [control]="form.get('passwordConfirm')"></rrs-form-errors>
          </div>

          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="form.disabled">
            {{ 'rrs.account.setPassword.submit.label' | cxTranslate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-container>
