import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { RrsAddToWishlistModalComponent } from '@app/custom/features/rrs-add-to-wishlist/components/rrs-add-to-wishlist-modal/rrs-add-to-wishlist-modal.component';
import { RrsAuthComponent } from '@app/custom/features/rrs-auth/rrs-auth/rrs-auth.component';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  Product,
  ProductService,
} from '@spartacus/core';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-add-to-wishlist',
  templateUrl: './rrs-add-to-wishlist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddToWishlistComponent implements OnInit {
  private subscriptions = new Subscription();
  private loggedIn!: boolean;
  icons = ICON_TYPE_LIST;

  @Input() product!: Product;
  @Input() productCode!: string;
  @Input() disabled = false;
  @Input() newDesign = false;

  constructor(
    protected modalService: ModalService,
    protected authService: AuthService,
    protected productService: ProductService,
    protected globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    const loggedInSub = this.authService
      .isUserLoggedIn()
      .subscribe((isLoggedIn) => {
        this.loggedIn = isLoggedIn;
      });
    this.subscriptions.add(loggedInSub);
  }

  onClick(): void {
    if (!this.disabled) {
      this.openAddToWishlistModal();
      return;
    }
    this.globalMessageService.add(
      {
        key: 'rrs.wishlist.pleaseSelectSize',
      },
      GlobalMessageType.MSG_TYPE_INFO
    );
  }

  openAddToWishlistModal(): void {
    if (!this.loggedIn) {
      this.modalService.open(RrsAuthComponent);
      return;
    }
    const modalRef = this.modalService.open(RrsAddToWishlistModalComponent, {
      windowClass: 'rrs-add-to-wishlist-modal',
    });
    modalRef.componentInstance.data = {
      product: this.product,
      productCode: this.productCode,
    };
  }
}
