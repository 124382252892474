import { Injectable, OnDestroy } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsCheckoutStepsParams } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import {
  NonGenericPagesIds,
  NonGenericPagesSemanticRoutes,
  RrsCartPageEvent,
  RrsCategoryPageEvent,
  RrsCheckoutPageEvent,
  RrsGenericPageEvent,
  RrsMyProfilePageEvent,
  RrsPageInfo,
  RrsProductDetailsPageEvent,
  RrsSearchPageEvent,
} from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { Cart } from '@spartacus/cart/base/root';
import {
  createFrom,
  EventService,
  isNotUndefined,
  ProductScope,
  ProductSearchPage,
  ProductSearchService,
  ProductService,
} from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { EMPTY, Observable } from 'rxjs';
import { filter, map, skip, switchMap, take } from 'rxjs/operators';
import { getCartInfo } from './utils/utils';

@Injectable({
  providedIn: 'root',
})
export class RrsPageEventsBuilder implements OnDestroy {
  constructor(
    protected eventService: EventService,
    protected productSearchService: ProductSearchService,
    protected activeCartService: RrsActiveCartService,
    protected productService: ProductService
  ) {
    this.register();
  }

  eventSourceTeardowns = Array<() => void>();

  ngOnDestroy(): void {
    this.eventSourceTeardowns.forEach((unregister) => unregister());
  }

  protected register(): void {
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsSearchPageEvent,
        this.buildSearchPageEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsCategoryPageEvent,
        this.buildCategoryPageEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsGenericPageEvent,
        this.buildGenericPageInfo()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(RrsCartPageEvent, this.buildCartPageEvent())
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsCheckoutPageEvent,
        this.buildCheckoutPageEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsProductDetailsPageEvent,
        this.buildProductDetailsPageEvent()
      )
    );
    this.eventSourceTeardowns.push(
      this.eventService.register(
        RrsMyProfilePageEvent,
        this.buildMyProfilePageEvent()
      )
    );
  }

  protected buildSearchPageEvent(): Observable<RrsSearchPageEvent> {
    const searchResults$ = this.productSearchService.getResults().pipe(
      // skipping the initial value, and preventing emission of the previous search state
      skip(1)
    );

    return this.eventService.get(NavigationEvent).pipe(
      switchMap((event) => {
        if (event.context?.id !== 'search' || !event.url?.includes('/search'))
          return EMPTY;

        return searchResults$.pipe(
          map((searchResults) =>
            createFrom(RrsSearchPageEvent, {
              page: this.getSearchPageInfo(event, searchResults),
            })
          )
        );
      })
    );
  }

  protected buildCategoryPageEvent(): Observable<RrsCategoryPageEvent> {
    const searchResults$ = this.productSearchService.getResults().pipe(
      // skipping the initial value, and preventing emission of the previous search state
      skip(1)
    );

    return this.eventService.get(NavigationEvent).pipe(
      switchMap((event) => {
        if (event.context?.id !== 'search' || event.url?.includes('/search'))
          return EMPTY;

        return searchResults$.pipe(
          map((searchResults) =>
            createFrom(RrsCategoryPageEvent, {
              page: this.getCategoryPageInfo(event, searchResults),
            })
          )
        );
      })
    );
  }

  protected buildGenericPageInfo(): Observable<RrsGenericPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((event) => this.getIsPageGeneric(event)),
      map(() =>
        createFrom(RrsGenericPageEvent, {
          page: {
            pageInfo: {
              pageName: ' page',
            },
            category: {
              pageType: '',
            },
          },
        })
      )
    );
  }

  protected buildCartPageEvent(): Observable<RrsCartPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((event) => event.context?.id === '/cart'),
      switchMap(() =>
        this.activeCartService.getActive().pipe(
          take(1),
          map((cart) =>
            createFrom(RrsCartPageEvent, this.getCartPageInfo(cart))
          )
        )
      )
    );
  }

  protected buildProductDetailsPageEvent(): Observable<RrsProductDetailsPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((navigationEvent) => navigationEvent.semanticRoute === 'product'),
      switchMap((navigationEvent) =>
        this.productService
          .get(navigationEvent.context.id, ProductScope.DETAILS)
          .pipe(
            filter((product) => Boolean(product)),
            take(1),
            map((product) =>
              createFrom(RrsProductDetailsPageEvent, {
                page: {
                  pageInfo: {
                    pageName: 'product details page',
                    pageLoadEvent: 'prodView,event43',
                  },
                  category: {
                    pageType: 'product details',
                    primaryCategory: 'product details page',
                  },
                },
                product: {
                  productInfo: {
                    sku: product?.code ?? '',
                  },
                },
              })
            )
          )
      )
    );
  }

  protected buildCheckoutPageEvent(): Observable<RrsCheckoutPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((event) => RrsCheckoutStepsParams.includes(event.context?.id)),
      switchMap((event) =>
        this.activeCartService.getActive().pipe(
          take(1),
          map((cart) =>
            createFrom(RrsCheckoutPageEvent, {
              cart: getCartInfo(cart),
              page: this.getCheckoutPageInfo(event),
            })
          )
        )
      )
    );
  }

  protected buildMyProfilePageEvent(): Observable<RrsMyProfilePageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((event) => event.context?.id === 'my-account'),
      map((event) =>
        createFrom(RrsMyProfilePageEvent, {
          page: this.getMyAccountPageInfo(event),
        })
      )
    );
  }

  private getCategoryPageInfo(
    event: NavigationEvent,
    searchResults: ProductSearchPage
  ): RrsPageInfo {
    const [primaryCategory, subCategory1, subCategory2] =
      event.params.query?.split('-') ?? [];
    const pageName =
      event.params.query
        ?.replace(/-/g, ',')
        ?.replace(/\b\w/g, (char: string) => char.toUpperCase()) || '';
    return {
      pageInfo: {
        pageName: pageName + ' | product listing page',
        searchResultCount: searchResults.pagination?.totalResults,
        searchResultProducts:
          searchResults.products?.map((product) => ({
            code: product.code,
            price: product?.price?.value ?? 0.0,
            originalPrice: product?.oldPrice?.value ?? 0.0,
          })) ?? [],
      },
      category: {
        pageType: 'product listing',
        ...(primaryCategory && { primaryCategory }),
        ...(subCategory1 && { subCategory1 }),
        ...(subCategory2 && { subCategory2 }),
      },
    };
  }

  private getSearchPageInfo(
    event: NavigationEvent,
    searchResults: ProductSearchPage
  ): RrsPageInfo {
    return {
      pageInfo: {
        pageName: 'internal search|search results  page',
        searchKeywords: event.params.query ?? '',
        searchResultCount: searchResults.pagination?.totalResults,
        searchResultProducts:
          searchResults.products?.map((product) => ({
            code: product.code,
            price: product?.price?.value ?? 0.0,
            originalPrice: product?.oldPrice?.value ?? 0.0,
          })) ?? [],
        // event11 used when search is performed
        // event12 used when there are search results
        // event13 used when there are no search results
        searchEvent:
          'event11' +
          ((searchResults.pagination?.totalResults ?? 0) > 0
            ? ',event12'
            : ',event13'),
      },
      category: {
        pageType: 'internal search',
        primaryCategory: 'internal search',
        subCategory1: 'internal search|search results',
      },
    };
  }

  private getCartPageInfo(cart: Cart): RrsCartPageEvent {
    const pageInfo: RrsCartPageEvent = {
      page: {
        pageInfo: {
          pageName: 'cart summary page',
          pageLoadEvent: 'scView',
        },
        category: {
          pageType: 'cart',
        },
      },
      cart: getCartInfo(cart),
    };

    if (!(cart?.entries ?? []).length) {
      pageInfo.cart = undefined;
    }

    return pageInfo;
  }

  private getCheckoutPageInfo(event: NavigationEvent): RrsPageInfo {
    let pageName = '';
    let pageLoadEvent = '';

    switch (event.context.id) {
      case '/review-order':
        pageName = 'order checkout|review order page';
        pageLoadEvent = 'event47';
        break;
      case '/payment-details':
        pageName = 'order checkout|payment page';
        pageLoadEvent = 'event46';
        break;
      case '/delivery-mode':
      case '/delivery-address':
        pageName = 'order checkout|shipping page';
        pageLoadEvent = 'scCheckout,event45,event9';
        break;
      default:
        break;
    }

    return {
      pageInfo: {
        pageName: pageName,
        pageLoadEvent: pageLoadEvent,
      },
      category: {
        pageType: 'checkout',
        primaryCategory: 'order checkout',
        subCategory1: pageName,
      },
    };
  }

  private getMyAccountPageInfo(event: NavigationEvent): RrsPageInfo {
    let pageName = '';

    switch (event.url) {
      case '/my-account/rewards':
        pageName = 'my account|my rewards summary page';
        break;
      case '/my-account/profile':
        pageName = 'my account|edit your account info page';
        break;
      case '/my-account/address-book':
        pageName = 'my account|my shipping addresses page';
        break;
      case '/my-account/payment-details':
        pageName = 'my account|my payment info page';
        break;
      case '/my-account/wishlists':
        pageName = 'my account|my wishlist page';
        break;
      case '/my-account/order-history':
        pageName = 'my account|my account information page';
        break;
      case '/my-account/claim-rewards':
        pageName = 'my account|my account information page';
        break;
      default:
        break;
    }

    return {
      pageInfo: {
        pageName: pageName,
      },
      category: {
        pageType: 'my account',
        primaryCategory: 'my account',
        subCategory1: pageName,
      },
    };
  }

  private getIsPageGeneric(event: NavigationEvent): boolean {
    return !(
      NonGenericPagesIds?.includes(event.context?.id) ||
      (isNotUndefined(event.semanticRoute) &&
        NonGenericPagesSemanticRoutes.includes(event.semanticRoute))
    );
  }
}
