export const PRE_CHAT_SCRIPT_SRC =
  'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js';
export const FRESH_CHAT_SCRIPT_SRC = 'https://wchat.freshchat.com/js/widget.js';
export const FRESH_CHAT_TOKEN = '50a246f0-7d43-4e6a-9746-743be4e43825';
export const FRESH_CHAT_HOST = 'https://wchat.freshchat.com';

export const PRE_CHAT_TEMPLATE = {
  mainbgColor: '#eeeeee',
  maintxColor: '#333333',
  heading: 'Customer Care',
  textBanner:
    "We can't wait to talk to you. But first, please take a couple of moments to tell us a bit about yourself.",
  SubmitLabel: 'Start Chat',
  fields: {
    field1: {
      type: 'name',
      label: 'Name',
      fieldId: 'name',
      required: 'yes',
      error: 'Please Enter a valid name',
    },
    field2: {
      type: 'email',
      label: 'Email',
      fieldId: 'email',
      required: 'yes',
      error: 'Please Enter a valid Email',
    },
    field3: {
      type: 'phone',
      label: 'Phone',
      fieldId: 'phone',
      required: 'no',
      error: 'Please Enter a valid Phone Number',
    },
  },
};

export const FRESH_CHAT_CONFIG = {
  headerProperty: {
    backgroundColor: '#FF6400',
    foregroundColor: '#FFFFFF',
    hideChatButton: true,
    fontName: 'Montserrat',
    fontUrl: 'https://fonts.googleapis.com/css?family=Montserrat',
  },
  cssNames: {
    widget: 'custom_fc_frame',
    expanded: 'custom_fc_expanded',
  },
};
