<section class="searchbox" role="search">
  <ng-container *ngIf="results$ | async as result">
    <ng-container *ngIf="result.products?.length">
      <span [ngClass]="{ backdrop: isShowingResults }"></span>
    </ng-container>
  </ng-container>
  <div
    class="searchbox__container"
    [class.searchbox__container--isDirty]="!!searchInput.value">
    <div class="d-flex me-2">
      <rrs-icon
        (click)="clear(searchInput)"
        [icon]="iconTypeList.CLOSE_BIG"
        class="searchbox__icon searchbox__icon--close"></rrs-icon>

      <rrs-icon
        [icon]="iconTypeList.SEARCH"
        class="searchbox__icon searchbox__icon--search"></rrs-icon>
    </div>

    <input
      (blur)="closeSearchBox($any($event))"
      (click)="openSearchBox()"
      (focus)="openSearchBox()"
      (input)="onSearchInput(searchInput.value)"
      (keydown.arrowup)="focusPreviousChild($any($event))"
      (keydown.arrowdown)="focusNextChild($any($event))"
      (keydown.enter)="
        closeSearchBox($any($event), true);
        launchSearchResult($any($event), searchInput.value);
        updateChosenWord(searchInput.value)
      "
      (keydown.escape)="closeSearchBox($any($event))"
      [attr.aria-label]="'Search'"
      [placeholder]="'rrs.searchbox.placeholder' | cxTranslate"
      #searchInput
      id="searchInput"
      aria-controls="results"
      aria-describedby="initialDescription"
      autocomplete="off"
      class="searchbox__input"
      data-cy="searchbox-input"
      value="{{ chosenWord }}" />
  </div>
</section>

<section
  *ngIf="results$ | async as result"
  class="searchbox-results bg-stripe z-1000"
  data-cy="searchbox-results"
  id="results">
  <div class="container-fluid bg-white">
    <ng-container *ngIf="result.products?.length; else noResults">
      <ul class="row row-cols-4 proucts list-none" role="listbox">
        <li *ngFor="let product of result.products">
          <a
            (blur)="closeSearchBox($any($event))"
            (click)="
              dispatchProductEvent({
                freeText: searchInput.value,
                productCode: product.code
              });
              clear(searchInput)
            "
            (mousedown)="preventDefault($event)"
            [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
            class="text-decoration-none"
            data-cy="searchbox-item">
            <div class="searchbox-results__imgs">
              <rrs-media
                [isSearchBoxImg]="true"
                [container]="product.images?.PRIMARY"
                height="160"
                width="444"></rrs-media>
            </div>

            <h3 class="fw-bold fs-6 mt-2" data-cy="searchbox-item-category">
              {{ product.manufacturer }}
            </h3>

            <h4
              class="text-truncate text-truncate--2 fw-normal fs-6"
              data-cy="searchbox-item-name">
              {{ product.name }}
            </h4>

            <p
              *ngIf="product.price?.isVisible; else priceHidden"
              class="fs-4 fw-bold text-accent">
              {{ product.price?.formattedValue }}

              <span
                class="fs-6 fw-normal text-light text-decoration-line-through"
                *ngIf="
                  product.oldPrice?.formattedValue &&
                  product.oldPrice?.formattedValue !==
                    product.price?.formattedValue
                "
                >{{ product.oldPrice?.formattedValue }}</span
              >
            </p>
            <ng-template #priceHidden>
              <p>
                {{ 'rrs.product.SeePriceInCart' | cxTranslate }}
              </p>
            </ng-template>
          </a>
        </li>
      </ul>

      <ul class="suggestions list-none" role="listbox">
        <li class="d-flex justify-content-center">
          <a
            (blur)="closeSearchBox($any($event))"
            (click)="clear(searchInput)"
            [routerLink]="
              {
                cxRoute: 'search',
                params: { query: searchInput.value }
              } | cxUrl
            "
            class="btn btn-primary btn-sm mb-3"
            data-cy="searchbox-see-all">
            {{
              'rrs.searchbox.seeAllResults'
                | cxTranslate: { query: searchInput.value }
            }}
          </a>
        </li>
      </ul>
    </ng-container>
    <ng-template #noResults>
      <p class="py-2 fw-bold">{{ 'rrs.searchbox.noResoults' | cxTranslate }}</p>
    </ng-template>
  </div>
</section>
