<ng-container *ngIf="productGallery$ | async as productGallery">
  <section
    class="product-details-images mb-4"
    *ngIf="{ visible: isDesktop$ | async } as isDesktop">
    <swiper
      #swiper
      [config]="configMain"
      [thumbs]="{ swiper: thumbsSwiper }"
      (slideChange)="onSlideChange()"
      class="mb-2">
      <ng-template swiperSlide *ngFor="let item of productGallery">
        <div class="product-details-images__main">
          <rrs-media
            (click)="openModal(isDesktop.visible)"
            [container]="item.container | rrsProductImages"
            height="416"
            width="624"
            format="product"
            tabindex="0"
            priority="true">
          </rrs-media>

          <rrs-icon
            [icon]="iconTypeList.SEARCH_PLUS"
            [size]="48"
            class="position-absolute bottom-0 end-0"></rrs-icon>
        </div>
      </ng-template>
    </swiper>

    <div
      [class.d-none]="isDesktop.visible === false"
      class="swiper-arrow-container mt-4">
      <swiper (swiper)="thumbsSwiper = $event" [config]="configTumbs">
        <ng-template swiperSlide *ngFor="let item of productGallery">
          <div class="product-details-images__item p-2">
            <rrs-media
              [container]="item.container | rrsProductImages : scene7Preset"
              height="78"
              width="126"
              format="product"
              tabindex="0">
            </rrs-media>
          </div>
        </ng-template>
      </swiper>

      <div class="swiper-arrow-prev d-none d-lg-flex">
        <rrs-icon [icon]="iconTypeList.CHEVRON_LEFT"></rrs-icon>
      </div>
      <div class="swiper-arrow-next d-none d-lg-flex">
        <rrs-icon [icon]="iconTypeList.CHEVRON_RIGHT"></rrs-icon>
      </div>
    </div>
  </section>
</ng-container>
