import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { RrsProductFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-product-facet-service.service';
import { CmsConfig, provideConfig, RoutingConfig } from '@spartacus/core';
import { ProductFacetService } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    provideConfig({
      slots: {
        ProductList: ['ProductListComponent'],
      },
    } as CmsComponentsConfig),
    provideConfig({
      routing: {
        routes: {
          search: {
            paths: ['search/:query', 'search', 'cs/:query', 'brands/:query'],
          },
        },
      },
    } as RoutingConfig),
    provideConfig({
      featureModules: {
        ['RRS_PLP_FEATURE']: {
          module: () =>
            import('./rrs-product-listing.module').then(
              (m) => m.RrsProductListingModule
            ),
          cmsComponents: ['ProductListComponent'],
        },
      },
    } as CmsConfig),
    {
      provide: ProductFacetService,
      useClass: RrsProductFacetService,
    },
  ],
})
export class RrsProductListingFeatureModule {}
