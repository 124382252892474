import {
  ALGOLIA_SEARCH_NORMALIZER,
  RrsSearchNormalizer,
} from '@app/custom/features/rrs-search/adapter/rrs-search.normalizer';
import { I18nModule, ProductSearchAdapter, UrlModule } from '@spartacus/core';
import { MediaModule, SearchBoxModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsIconModule } from '@app/shared/components';
import { RrsSearchAdapter } from '@app/custom/features/rrs-search/adapter/rrs-search.adapter';
import { RrsSearchboxComponent } from './components/rrs-searchbox/rrs-searchbox.component';
import { RrsSearchboxMobileComponent } from './components/rrs-searchbox-mobile/rrs-searchbox-mobile.component';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';

@NgModule({
  declarations: [RrsSearchboxComponent, RrsSearchboxMobileComponent],
  exports: [RrsSearchboxComponent, RrsSearchboxMobileComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    RouterModule,
    RrsIconModule,
    SearchBoxModule,
    UrlModule,
    RrsMediaModule,
  ],
  providers: [
    { provide: ProductSearchAdapter, useClass: RrsSearchAdapter },
    {
      provide: ALGOLIA_SEARCH_NORMALIZER,
      useClass: RrsSearchNormalizer,
      multi: true,
    },
  ],
})
export class RrsSearchModule {}
