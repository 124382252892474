<nav class="header-menu">
  <ul class="d-flex align-items-center list-none header-menu__list">
    <li
      *ngFor="let item of menu[0].top_level_sections"
      class="header-menu__item"
      data-cy="header-menu">
      <a
        [attr.title]="item.section.section_title"
        [routerLink]="item.section.url"
        target="{{ item.section.new_tab ? '_blank' : '_self' }}"
        class="header-menu__link text-decoration-none"
        hideMegaMenu>
        {{ item.section.section_title }}
      </a>

      <rrs-cms-header-mega-menu
        [banner]="item.section.ad"
        [menu]="item.section.second_level_sections"></rrs-cms-header-mega-menu>
    </li>
  </ul>
</nav>
