import {
  AuthRedirectService,
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  RoutingService,
} from '@spartacus/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateEmailComponentService } from '@spartacus/user/profile/components';
import { UserEmailFacade } from '@spartacus/user/profile/root';
import { take } from 'rxjs/operators';

@Injectable()
export class RrsAccountInformationService extends UpdateEmailComponentService {
  constructor(
    protected authRedirectService: AuthRedirectService,
    protected authService: AuthService,
    protected globalMessageService: GlobalMessageService,
    protected modalService: NgbModal,
    protected routingService: RoutingService,
    protected userEmail: UserEmailFacade
  ) {
    super(
      userEmail,
      routingService,
      globalMessageService,
      authService,
      authRedirectService
    );
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      CustomFormValidators.emailValidator,
    ]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    const newEmail = this.form.get('email')?.value;
    const password = this.form.get('password')?.value;

    this.userEmail
      .update(password, newEmail)
      .pipe(take(1))
      .subscribe({
        next: () => this.loginWithNewCredentials(newEmail, password),
        error: (error: Error) => this.onError(error),
      });
  }

  protected onError(error: HttpErrorModel): void {
    if (error.details?.[0].message?.length) {
      this.globalMessageService.add(
        error.details[0].message,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
    this.modalService.dismissAll();
    this.busy$.next(false);
    this.form.reset();
  }

  protected loginWithNewCredentials(newUid: string, password: string): void {
    this.authService.coreLogout().then(() => {
      this.authService.loginWithCredentials(newUid, password).then(() => {
        this.modalService.dismissAll();
        this.busy$.next(false);
        this.form.reset();

        this.globalMessageService.add(
          {
            key: 'rrs.account.form.accountUpdateSuccess',
          },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      });
    });
  }
}
